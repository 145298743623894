<template>
  <div>
    <div class="label" v-if="textFloat">{{ textFloat }}</div>
    <div class="d-flex align-items-center">
      <input
        type="color"
        id="head"
        :ref="name"
        :name="name"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
      <div class="color-select" @click="toggleColorPicker()">
        <label class="mb-0">{{ value || "Select Color" }}</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["textFloat", "value", "name"],
  methods: {
    toggleColorPicker() {
      this.$refs[this.name].click();
    },
  },
};
</script>

<style>
.color-select {
  /* border: 1px solid #ccc; */
  background-color: white;
  border: 1px solid #4f4f4f;
  border-radius: 2px;
  padding: 3px 13px;
  height: 40px;
  display: flex;
  align-items: center;
  min-width: 100px;
  white-space: nowrap;
  margin-left: -3px;
  z-index: 1;
  border-left: none;
}
input[type="color"] {
  z-index: 2;
  height: 40px;
  /* border-radius: unset; */
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-color: #4f4f4f;
}
</style>
